
import Modal from '../../UI/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { ReactSession } from 'react-client-session';
import { useNavigate } from 'react-router-dom';
const AddNewLinkPopUp = (props) => {
    const [link, setLink] = useState('');
    //const [autoCompleteSiteList, setAutoCompleteSiteList] = useState([{ label: "Select ", id: 0 }]);
    const [siteIDSelected, setSiteIDSelected] = useState('');
    const [siteId, setSiteId] = useState(props.siteSelected);
    const [title, setTitle] = useState('');
    const userId = ReactSession.get('UserID');

    const roleId = useState(ReactSession.get("RoleID"));

    console.log(userId);
    const navigate = useNavigate();
    
    //

    const onFormSubmitHandler = async (event) => {
        event.preventDefault();
        const postId = await addLink(1);
        console.log(postId);
        if (postId == 0) {
            alert("ERROR!!! Link is already added for given google doc!");
        }
        else {
            updateNote(postId, "New Doc Added.");
            if (siteId.id > 0) {
                props.contentAdded();
                props.onCloseModal();
                //navigate('/EditLinkContent?postid=' + postId + '&siteid=' + siteId.id, { replace: false });
                //window.location.href = '/EditLinkContent?postid=' + postId + '&siteid=' + siteId.id;
            }
            else {
                alert("Please select site!");

            }
        }
        
    }

    const onSaveAndApprove = async (event) => {
        event.preventDefault();
        const postId = await addLink(2);
        
        if (postId == 0) {
            alert("ERROR!!! Link is already added for given google doc!");
        }
        else {
            updateNote(postId, "New Doc Added. Submitted for image finding.");
            if (siteId.id > 0) {
                props.contentAdded();
                props.onCloseModal();
                //navigate('/EditLinkContent?postid=' + postId + '&siteid=' + siteId.id, { replace: false });
                //window.location.href = '/EditLinkContent?postid=' + postId + '&siteid=' + siteId.id;
            }
            else {
                alert("Please select site!");

            }
        }

    }

    const titleInputHandler = (event) => {
        setTitle(event.target.value);
    }
    const linkInputHandler = (event) => {
        setLink(event.target.value);
    }
    const siteSelectionChanged = async (event, value) => {
        //setSiteName(value.label);
        setSiteId(value);
    }

    async function updateNote(post_id, post_note) {

        try {
            const data = {
                post_id: post_id, notes: post_note, updated_by: userId
            };

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/updatenotes', config)

            if (response.ok) {

            } else {

            }
        } catch (error) {
            console.log(error);
            return -1;
        }
    }

    async function addLink(newStatus) {
        try {
            let status = newStatus;
            if (roleId == 1)
                status = 2;
            const data = { id: 0, post_id: 0, status: status, post_url: link, post_title: title, site_id: siteId.id, wp_id: 0, content: '', updated_by: userId, content_editing:0, is_google_doc: 1, is_draft:0 };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/addnewdoc', config)

            if (response.ok) {
                const json = await response.json();
                console.log(json[0].id);

                return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    return (
        <Modal >
            <div className="container">

                <div className="row">
                    <div className="col">

                        <h5>Message</h5>
                        <Box>
                        </Box>
                    </div>

                </div>
                <form onSubmit={onFormSubmitHandler}>


                    <div className="form-group">
                        <TextField
                            id="subject"
                            label="Google Doc Url"
                            value={link}
                            onChange={linkInputHandler}
                            fullWidth
                            margin="normal"
                            error={link === ""}
                            helperText={link === "" ? 'Please provide google doc url!' : ' '}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            id="title"
                            label="Title"
                            value={title}
                            onChange={titleInputHandler}
                            fullWidth
                            margin="normal"
                            error={title === ""}
                            helperText={title === "" ? 'Please provide subject!' : ' '}
                            required
                        />
                    </div>
                    <div className="form-group">

                        <Autocomplete
                            autoSelect={true}
                            disablePortal
                            disableClearable
                            id="combo-box-demo"
                            value={props.siteSelected}
                            inputValue={siteIDSelected}
                            onInputChange={(event, newInputValue) => {
                                setSiteIDSelected(newInputValue);
                            }}
                            //isOptionEqualToValue={(option, siteId) => option.id === siteId.id}
                            options={props.sites}
                            sx={{ width: 300, height:300 }}
                            onChange={siteSelectionChanged}
                            renderInput={(params) => 
                                <TextField {...params} label="Select Site"
                                  

                                />}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" className="btn btn-primary" onClick={onSaveAndApprove }>Submit & Approve</button>
                    <button type="button" className="btn btn-default" onClick={props.onCloseModal} >Cancel</button>

                </form>
            </div>
        </Modal>
    );
}

export default AddNewLinkPopUp;